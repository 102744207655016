import {defineStore, storeToRefs} from 'pinia'
import {secureStorage} from '@/utils/secureStorage'
import {fetchCommonCodes, fetchCompanyInfo, fetchErrorCodes} from '@/api/common'
import {getShortenHash} from '@/api/contract'
import {useCustomerStore} from '@/stores/customer'
import {REDIRECT_FLAG_KEY} from '@/core/common'
import {formattedPhoneNumber} from '@/composables/format'

export const useCommonStore = defineStore('common', {
  state: () => ({
    isRequiredMask: false,
    isMobile: window.innerWidth <= 800,
    commonCodeList: [], // 공통 코드
    companyInfo: {
      initial: '', // 기본값
      name: '',
      companyNumber: '',
    },
    couponNumber: '',
    /* 팝업 */
    certifyPopup: {
      show: false,
      redirectPath: null,
    },
    confirmPopup: {
      show: false,
      mainMessage: '',
      subMessage: '',
      type: '',
      onClickConfirm: null,
      cancelBtnName: null,
      confirmBtnName: null,
    },
    alertPopup: {
      show: false,
      mainMessage: '',
      subMessage: '',
      errorCode: '',
    },
    contractForm: {},
    errorCodeList: [],
    needToReturn: false,
  }),
  persist: {
    storage: secureStorage,
    paths: ['commonCodeList', 'companyInfo', 'errorCodeList', 'needToReturn', 'contractForm', 'couponNumber'],
  },
  getters: {},
  actions: {
    setConfirmPopup({
      mainMessage = '알림',
      subMessage = '',
      callback = null,
      type = null,
      cancelBtnName = null,
      confirmBtnName = null,
    }) {
      this.confirmPopup.show = true
      this.confirmPopup.mainMessage = mainMessage
      this.confirmPopup.subMessage = subMessage
      this.confirmPopup.cancelBtnName = cancelBtnName
      this.confirmPopup.confirmBtnName = confirmBtnName
      this.confirmPopup.onClickConfirm = callback
      this.confirmPopup.type = type
    },
    setCertifyPopup(redirectPath = null, existingDataResponse = {}, onConfirm = null) {
      this.certifyPopup.show = true
      this.certifyPopup.redirectPath = redirectPath
      this.certifyPopup.existingDataResponse = existingDataResponse
      this.certifyPopup.onConfirm = onConfirm
    },
    setAlertPopup(mainMessage, subMessage, errStatus = null) {
      this.alertPopup.show = true

      if (this.errorCodeList[errStatus]) {
        this.alertPopup.subMessage = this.errorCodeList[errStatus]
      } else {
        this.alertPopup.subMessage = subMessage
      }

      this.alertPopup.mainMessage = mainMessage
      this.alertPopup.errorCode = errStatus
    },

    async fetchRequiredCodes(companyInitial = this.companyInfo.initial) {
      fetchErrorCodes().then(res => {
        this.errorCodeList = res
      })

      try {
        this.companyInfo = await fetchCompanyInfo({companyInitial})
        this.commonCodeList = await fetchCommonCodes(companyInitial)
        sessionStorage.removeItem(REDIRECT_FLAG_KEY)
      } catch (err) {
        sessionStorage.setItem('redirect-home', 'true')
      }
    },

    parseHash(hashString) {
      return getShortenHash(hashString).then(({originalString}) => {
        const {companyInitial, contractFormId, phoneNumber} = JSON.parse(originalString)
        const {customerInfo} = storeToRefs(useCustomerStore())

        this.fetchRequiredCodes(companyInitial)
        customerInfo.value.contractFormId = contractFormId
        customerInfo.value.phoneNumber = formattedPhoneNumber(phoneNumber)
        this.companyInfo.initial = companyInitial

        return companyInitial
      })
    },

    setCouponNumber(number) {
      this.couponNumber = number
    },
  },
})
