<template>
  <div
    class="modal fade show"
    v-if="certifyPopup.show"
    id="exampleModalLive"
    tabindex="-1"
    aria-labelledby="exampleModalLiveLabel"
    aria-modal="true"
    role="dialog"
    style="display: block; z-index: 500"
  >
    <div :class="certifyPopup.show ? 'modal-show' : 'modal'" tabindex="-1">
      <div class="modal-custom" style="width: 320px">
        <div class="modal-content">
          <div class="modal-message-box main">
            <span class="title" v-html="title"></span>
          </div>
          <div style="padding: 24px 20px 0">
            <span>휴대폰 번호</span>
            <div class="d-flex mgt-8">
              <input
                class="input-phone"
                type="text"
                placeholder="휴대폰 번호를 입력"
                inputmode="numeric"
                v-maska:[handPhoneMasking]
                v-model="proxyPhoneNumber"
                disabled
              />
            </div>

            <div class="d-flex mgt-8 align-items-center" style="gap: 8px">
              <input
                class="input-phone input-birth"
                type="text"
                placeholder="주민등록번호"
                inputmode="numeric"
                maxlength="6"
                v-model="proxyPersonalNumberBirth"
              />
              <span style="color: #9e9e9e">-</span>
              <input
                class="input-phone input-personal"
                type="text"
                inputmode="numeric"
                maxlength="1"
                v-model="proxyGenderNumber"
              />
              <span class="personal-dot" v-for="_ in 6" />
            </div>

            <a class="phone-auth mgt-8" v-if="!$route.query.n" @click="onClickAuthRequest">{{
              isAuthComplete.authRequest ? '재요청' : '인증요청'
            }}</a>

            <template v-if="!$route.query.n">
              <span class="d-flex mgt-24">인증 정보 입력</span>
              <div class="d-flex mgt-8">
                <div class="d-flex auth-number-box">
                  <input
                    class="input-phone"
                    type="text"
                    placeholder="인증번호 6자리"
                    inputmode="numeric"
                    maxlength="6"
                    v-model="authNumber"
                  />
                  <span class="time" v-if="!isAuthComplete.authRequest || !isAuthComplete.authSend">
                    {{ timerStr }}</span
                  >
                </div>
              </div>

              <!--              <test-message-auth-btn @onClickTestAuth="onClickTestAuth" :phone-number="proxyPhoneNumber">-->
              <!--              </test-message-auth-btn>-->
              <div class="mgt-4">
                <span class="phone-auth-text" :style="activeCertifyCompleteStyle">
                  {{ getIsCertifyCompleteText() }}</span
                >
              </div>
            </template>
          </div>

          <div class="footer double confirm-double">
            <a @click="onCancel" type="button" class="close">취소</a>
            <a @click="onClickConfirm" type="button" class="confirm">확인</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useCommonStore} from '@/stores/common'
import {storeToRefs} from 'pinia'
import {useRoute, useRouter} from 'vue-router'
import {computed, ref} from 'vue'
import {useCustomerStore} from '@/stores/customer'
import {useValidationComposable} from '@/composables/validation'
import TestMessageAuthBtn from '@/components/register/common/TestMessageAuthBtn.vue'
import {REGISTER_TYPE} from '@/api/environment'
import {formatRemoveHyphens} from '@/composables/format'

const title = '본인 확인을 위해<br/> 인증을 진행해주세요'

const $router = useRouter()
const $route = useRoute()

const {handPhoneMasking} = useValidationComposable()
const {fetchMobileMessageAuthNumber, setMobileMessageAuth} = useCustomerStore()
const {customerInfo} = storeToRefs(useCustomerStore())

const {setAlertPopup} = useCommonStore()
const {companyInfo, certifyPopup, isRequiredMask} = storeToRefs(useCommonStore())

const authNumber = ref()

const timerStr = ref('03:00')
const timeCounter = ref(180)
const timer = ref(null)
const isAuthComplete = ref({authRequest: false, authSend: false})

const proxyPhoneNumber = ref(customerInfo.value.phoneNumber)
const proxyPersonalNumberBirth = ref('')
const proxyGenderNumber = ref('')
const isFailAuth = ref(false)

const activeAuthBtnClass = computed(() => {
  return {on: proxyPersonalNumberBirth.value?.length === 6 && proxyGenderNumber.value}
})
const onClickTestAuth = authMessage => {
  isAuthComplete.value.authRequest = true

  authNumber.value = authMessage
}

/**
 * message auth request
 */
const onClickAuthRequest = () => {
  if (!activeAuthBtnClass.value?.on) {
    return
  }

  if (isAuthComplete.value.authSend && isAuthComplete.value.authRequest) {
    return setAlertPopup('알림', '이미 인증되었습니다.')
  }

  const params = {
    ...customerInfo.value,
    phoneNumber: formatRemoveHyphens(proxyPhoneNumber.value),
    genderNumber: proxyGenderNumber.value,
    personalNumberBirth: proxyPersonalNumberBirth.value,
  }

  fetchMobileMessageAuthNumber($route.params.type, params)
    .then(() => {
      if (timer.value != null) {
        timerStop()
        timer.value = null
      }
      timer.value = timerStart()
      if (isAuthComplete.value.authRequest) {
        timerStr.value = '03:00'
        timeCounter.value = 180
        timer.value = null
      }
      isAuthComplete.value.authRequest = true
    })
    .catch(({response}) => {
      isAuthComplete.value.authRequest = false
      setAlertPopup('알림', '상담내역이 없습니다', response?.status)
    })
}

/**
 * message auth send
 */

const validateAuth = async () => {
  if (
    !isAuthComplete.value.authRequest ||
    authNumber.value?.length !== 6 ||
    (isAuthComplete.value.authSend && isAuthComplete.value.authRequest)
  ) {
    return false
  }
  try {
    await setMobileMessageAuth($route.params.type, {
      phoneNumber: formatRemoveHyphens(proxyPhoneNumber.value),
      authNumber: authNumber.value,
    })
    isAuthComplete.value.authSend = true
    isFailAuth.value = false
    timerStop()
    return true
  } catch {
    isFailAuth.value = true
    isAuthComplete.value.authSend = false
  }
}

/**
 * 인증요청 후 인증시간 3분 설정
 * setInterval make
 */
const timerStart = () => {
  // 1초에 한번씩 start 호출
  timeCounter.value = 180
  const interval = setInterval(() => {
    timeCounter.value-- //1초씩 감소
    timerStr.value = prettyTime()
    if (timeCounter.value <= 0) timerStop(interval)
  }, 1000)
  return interval
}
/**
 * clearInterval
 * */
const timerStop = () => {
  clearInterval(timer.value)
  timeCounter.value = 0
}
/**
 * make HH:SS format
 * @return {String}
 */
const prettyTime = () => {
  // 시간 형식으로 변환 리턴
  const time = timeCounter.value / 60
  const minutes = parseInt(time)
  const secondes = Math.round((time - minutes) * 60)
  return minutes.toString().padStart(2, '0') + ':' + secondes.toString().padStart(2, '0')
}

/**
 * css toggle
 * @return {String}
 */
const activeCertifyCompleteStyle = computed(() => {
  if (isAuthComplete.value.authRequest && !isAuthComplete.value.authSend) {
    return 'color:#C10015'
  } else {
    return 'color:#002E9B'
  }
})

const getIsCertifyCompleteText = () => {
  if (isFailAuth.value) {
    return '인증번호가 일치하지 않습니다'
  }
  if (isAuthComplete.value.authRequest && !isAuthComplete.value.authSend) {
    return '문자가 오지 않았다면 재요청 버튼을 눌러주세요.'
  }
  return ''
}
const onClickConfirm = async () => {
  if (!(await validateAuth())) {
    return
  }
  onSuccessAuth()
}
const onCancel = () => {
  certifyPopup.value.show = false
}
const onSuccessAuth = () => {
  isRequiredMask.value = false
  onCancel()
}
</script>

<style scoped>
.input-phone {
  width: 100%;
  height: 48px;
  padding-left: 12px;
}

.phone-auth {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.04em;
  color: #0c4ecc;
  width: 100%;
  height: 48px;
  border-radius: 3px;
  border: 1px solid #e0e0e0;
}

.phone-auth.send {
  width: 117px;
}

.auth-number-box {
  position: relative;
  width: 100%;
}

.auth-number-box .time {
  position: absolute;
  right: 8px;
  top: 12px;
  color: #002e9b;
}

.personal-dot {
  width: 8px;
  height: 8px;
  display: block;
  background-color: #9e9e9e;
  border-radius: 50%;
}

.input-personal {
  width: 36px;
  padding: 0;
  text-align: center;
}

.input-birth {
  width: 132px;
}
</style>
