import {defineStore} from 'pinia'
import {request} from '@/api/utils/apiUtil'
import {api, METHOD_TYPE} from '@/api/environment'
import {useCommonStore} from '@/stores/common'
import {useCustomerStore} from '@/stores/customer'

export const useReviewsStore = defineStore('Reviews', {
  getters: {
    getCompanyInitial() {
      return useCommonStore().companyInfo.initial
    },
    getCustomerPhoneNumber() {
      return useCustomerStore().customerInfo.phoneNumber
    },
  },
  actions: {
    // 고객이 등록한 후기 링크 정보
    fetchReviewInfo(contractId, codeId) {
      return request(
        METHOD_TYPE.GET,
        `${api.client.contractEventPayback}/${contractId}/cafe-blog/${this.getCompanyInitial}/${this.getCustomerPhoneNumber}?codeId=${codeId}`,
      )
    },
    // 후기 링크 정보 저장
    postReviewInfo(contractId, params, codeId) {
      return request(
        METHOD_TYPE.POST,
        `${api.client.contractEventPayback}/${contractId}/cafe-blog/${this.getCompanyInitial}/${this.getCustomerPhoneNumber}?codeId=${codeId}`,
        params,
      )
    },

    //약관 리스트 조회
    fetchReviewAgree(params) {
      return request(
        METHOD_TYPE.GET,
        `${api.client.counsel.customerApplication.event}/${this.getCompanyInitial}`,
        params,
      )
    },
  },
})
