import {api} from '../environment'
import {requestGetCache} from '@/api/utils/apiUtil'

/**
 * hashString Parse
 * @param originHash
 * @returns {Promise<void>}
 */
export const getShortenHash = originHash => {
    return requestGetCache(`${api.shortenHash}?hashString=${originHash}`)
}