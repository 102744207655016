import {request, requestGetCache} from "@/api/utils/apiUtil";
import {api, METHOD_TYPE} from "@/api/environment";

/**
 * 회사 정보 불러오기 => useCommonStore().companyInfo 저장 필
 * @param companyInitial
 * @returns {Promise<void>}
 */
export const fetchCompanyInfo = ({companyInitial}) => {
    return requestGetCache(`${api.client.company}`, {companyInitial})
}
/**
 * companyInitial 기반으로 공통 코드 조회
 * @param companyInitial
 * @returns {{}|Promise<unknown>}
 */
export const fetchCommonCodes = (companyInitial) => {
    return requestGetCache(`${api.client.product.code}/${companyInitial}`)
}

/**
 * 이벤트 지급 관련 공통 코드 전체 조회
 * @returns {Promise<void>}
 */
export const fetchEventCodes = () => {
    return requestGetCache(`${api.client.contractEventPayback}/code`)
}

/**
 * 에러 코드 조회
 * @returns {Promise<void>}
 */
export const fetchErrorCodes = () => {
    return requestGetCache(api.client.counsel.errorCodes)
}