import {datadogLogs} from '@datadog/browser-logs'
import {version} from '/package.json'

export default function () {
  if (import.meta.env.MODE === 'prod') {
    datadogLogs.init({
      clientToken: 'pub56ee745ab30ff5b88ddd317a6699e8f7',
      service: 'aj-crm-event',
      site: 'datadoghq.com',
      forwardErrorsToLogs: false,
      sessionSampleRate: 100,
      version,
    })
  }
}
