export const REGISTER_TYPE = {
    COUNSEL: 'counsel',
    BLOG: 'blog',
    CAFE: 'cafe',
    GIFT_CARD: 'giftcard',
}
export const METHOD_TYPE = {
    GET: 'get',
    POST: 'post',
    DOWNLOAD: 'download',
    DELETE: 'delete',
    PUT: 'put',
}
export const api = {
    shortenHash: '/shorten-hash',

    client: {
        testAuth: '/client/test/mobile-message-auth-number',
        company: '/client/company',
        counsel: {
            errorCodes: '/client/counsel/error-codes',
            selectedOptionCharge: '/client/counsel/selected-option-charge',
            mobileMessageAuthNumber: '/client/counsel/mobile-message-auth-number',
            contractForm: '/client/counsel/contractForm',
            customerApplication: {
                event: '/client/counsel/customer-application/event',
            },
        },
        product: {
            code: '/client/product/code', // 고객용 코드조회
        },
        // 상품권 교환
        contractEventPayback: '/client/contract-event-payback',
    },
}
