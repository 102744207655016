import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'vue-multiselect/dist/vue-multiselect.css'
import 'vue3-carousel/dist/carousel.css'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import {createPinia} from 'pinia'
import Datadog from '@/plugin/datadog'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import {createApp} from 'vue'
import multiSelect from 'vue-multiselect'
import App from './App.vue'
import router from './router'
import {vMaska} from 'maska'
import {createMetaManager} from 'vue-meta'

import '@css/font.css'
import '@css/reset.css'
import '@css/base.css'
import '@css/main.css'
import '@css/customer.css'
import '@css/common.css'
import '@css/gift.css'
import '@css/certify.css'
import '@css/modal.css'
import 'bootstrap/dist/js/bootstrap.js'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App)
app.use(pinia)
app.use(Datadog)
app.use(router)
app.use(createMetaManager())
app.directive('maska', vMaska)
app.component('multiSelect', multiSelect)
app.mount('#app')
