import axios from 'axios'
import router from '@/router'
import {useCommonStore} from '@/stores/common'
import {METHOD_TYPE} from '@/api/environment'
import {cache} from '@/utils/lruCache'
import {datadogRum} from '@datadog/browser-rum'
import {datadogLogs} from '@datadog/browser-logs'

function getPinia() {
  const $useCommonStore = useCommonStore()
  const {setAlertPopup} = $useCommonStore
  return {
    setAlertPopup,
  }
}

//axios 인스턴스 생성
const axiosInstance = axios.create({
  baseURL: `${import.meta.env.VITE_APP_API_BASE_URL}`,
  timeout: 30000,
  headers: {'Content-Type': 'application/json;charset=UTF-8', withCredentials: true},
  withCredentials: true,
  timeoutErrorMessage: '요청 시간이 초과되었습니다.',
})

//axios 인터셉터 처리
axiosInstance.interceptors.request.use(
  async config => {
    return config
  },
  function (err) {
    return Promise.reject(err)
  },
)

export const request = (method, url, data) => {
  if (method !== METHOD_TYPE.GET) {
    datadogRum.setGlobalContext({
      request: {
        body: data,
      },
    })
  }

  if (method === METHOD_TYPE.GET && !!data) {
    url += '?' + makeQuery(data)
  }

  return axiosInstance({
    method,
    url,
    data,
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      if (!!err?.response) {
        const {status, data} = err.response
        if (import.meta.env.MODE === 'prod' && method !== METHOD_TYPE.GET) {
          const {data, url} = err.config
          datadogLogs.logger.error(`${method.toUpperCase()}|${url}`, {body: JSON.parse(data)}, err)
        }
        if (status !== 200 && status !== 304 && data?.code !== 'C007') {
          const errMessage = err.response.data.message
          getPinia().setAlertPopup('알림', errMessage || '관리자에게 문의하세요', data?.code)
        }

        if (err.response) {
          throw {
            ...err,
            ...err.response.data,
          }
        }
      }

      throw err
    })
    .finally(() => {
      if (method !== METHOD_TYPE.GET) {
        datadogRum.clearGlobalContext()
      }
    })
}

const makeQuery = params => {
  return Object.keys(params)
    .map(key => key + '=' + params[key])
    .join('&')
}
export const requestGetCache = (url, data) => {
  const cachedData = cache.get(url)
  if (cachedData) {
    return cachedData
  }
  const result = request(METHOD_TYPE.GET, url, data)
  cache.set(url, result)

  return result
}

export const clearCache = () => {
  cache.clear()
}
