const routes = [
  {
    path: '/m/:company',
    name: 'CompanyMain',
    component: () => import('@/views/common/Main.vue'),
  },
  {
    path: '/',
    name: 'EmptyRedirect',
    component: () => import('@/views/common/Redirect.vue'),
  },
  {
    path: '/:company',
    name: 'CompanyRedirect',
    component: () => import('@/views/common/Redirect.vue'),
  },
  {
    path: '/r',
    name: 'HashRedirect',
    component: () => import('@/views/common/Redirect.vue'),
  },
  {
    path: '/:type/r',
    name: 'HashEnter',
    component: () => import('@/views/common/Redirect.vue'),
  },
  {
    path: '/:type/:company',
    name: 'CompanyEnter',
    component: () => import('@/views/common/AuthCustomer.vue'),
  },
  {
    path: '/:type/auth/:company',
    name: 'Auth',
    component: () => import('@/views/common/AuthCustomer.vue'),
  },
  {
    path: '/:type/contract/:company',
    name: 'Contract',
    component: () => import('@/views/common/SelectContract.vue'),
  },
  {
    path: '/:type/register/:company',
    name: 'Register',
    component: () => import('@/views/common/Register.vue'),
  },
  {
    path: '/:type/pass/:company',
    name: 'Pass',
    component: () => import('@/views/common/Pass.vue'),
  },
  // Version 확인용 페이지
  {
    path: '/version',
    name: 'Version',
    component: () => import('@/views/version/Index.vue'),
  },
]
export default routes
