import {createRouter, createWebHistory} from 'vue-router'
import {storeToRefs} from 'pinia'

import {useCommonStore} from '@/stores/common'

function getPinia() {
    return {
        confirmPopup: storeToRefs(useCommonStore()).confirmPopup,
    }
}

const getRoutes = () => {
    const modules = import.meta.glob('/src/router/modules/!(index).[j]s', {
        eager: true,
    })
    return Object.keys(modules).reduce((acc, key) => acc.concat(modules[key].default), [])
}

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: getRoutes(),
    scrollBehavior(to, from, savedPosition) {
        return {top: 0}
    },
})
router.beforeEach(async (to, from, next) => {
    if (getPinia().confirmPopup.value?.show) {
        getPinia().confirmPopup.value.show = false
    }

    return next()
})
export default router
