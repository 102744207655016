import {secureStorage} from '@/utils/secureStorage'
import {api, METHOD_TYPE} from '@/api/environment'
import {useCommonStore} from './common'
import {request} from '@/api/utils/apiUtil'
import {defineStore} from 'pinia'

export const useCustomerStore = defineStore('Customer', {
  state: () => ({
    customerInfoList: [],
    customerInfo: {
      phoneNumber: '',
      contractFormId: null,
    },
  }),
  getters: {
    getCompanyInitial() {
      return useCommonStore().companyInfo.initial
    },
  },
  persist: {
    storage: secureStorage,
    paths: ['customerInfo'],
  },
  actions: {
    // 핸드폰 인증 번호 확인
    fetchMobileMessageAuthNumber(type, params) {
      return request(
        METHOD_TYPE.POST,
        `${api.client.counsel.mobileMessageAuthNumber}/${type}/${this.getCompanyInitial}`,
        params,
      )
    },
    //개발자용 테스트 인증
    setTestAuth(url, params) {
      return request(METHOD_TYPE.POST, `${api.client.testAuth}/${url}/${this.getCompanyInitial}`, params)
    },
    //문자 인증
    setMobileMessageAuth(type, params) {
      return request(
        METHOD_TYPE.POST,
        `${api.client.counsel.mobileMessageAuthNumber}/${type}/${this.getCompanyInitial}/auth`,
        params,
      )
    },
  },
})
