<template>
  <metainfo></metainfo>
  <div class="layout">
    <header v-if="!isMobile || !isPass" :class="{mobile: isMobile}">
      <company-logo></company-logo>
    </header>
    <router-view :class="isMobile ? 'pc' : ''"></router-view>
  </div>
  <confirm-popup v-if="confirmPopup.show"></confirm-popup>
  <alert-popup v-if="alertPopup.show"></alert-popup>
  <certify-popup v-if="certifyPopup.show"></certify-popup>
</template>

<script setup>
import {computed, onBeforeUnmount, onMounted} from 'vue'
import {RouterView, useRoute, useRouter} from 'vue-router'
import {storeToRefs} from 'pinia'
import {useCustomerStore} from '@/stores/customer'
import {useReviewsStore} from '@/stores/reviews'
import {useCommonStore} from '@/stores/common'
import CompanyLogo from '@/components/common/CompanyLogo.vue'
import ConfirmPopup from '@/components/common/ConfirmPopup.vue'
import AlertPopup from '@/components/common/AlertPopup.vue'
import CertifyPopup from '@/components/common/CertifyPopup.vue'
import {clearCache} from '@/api/utils/apiUtil'
import {REDIRECT_FLAG_KEY} from '@/core/common'
import {datadogLogs} from '@datadog/browser-logs'

const $route = useRoute()
const $router = useRouter()

const $commonStore = useCommonStore()
const $customerStore = useCustomerStore()
const $reviewStore = useReviewsStore()

const {isMobile, companyInfo, commonCodeList, alertPopup, confirmPopup, certifyPopup} = storeToRefs(useCommonStore())
const {customerInfo} = storeToRefs(useCustomerStore())

const isPass = computed(() => {
  return $route.name === 'Pass'
})

onMounted(async () => {
  window.addEventListener('resize', handleResize)
  handleResize()

  sessionStorage.removeItem(REDIRECT_FLAG_KEY)

  const logUser = {}
  if (customerInfo.value?.phoneNumber) {
    logUser.name = customerInfo.value?.phoneNumber
  }

  if (customerInfo.value?.contractFormId) {
    logUser.id = customerInfo.value?.contractFormId
  }

  if (Object.keys(logUser).length > 0) {
    datadogLogs.setUser(logUser)
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)

  clearCache()
  $commonStore.$reset()
  $customerStore.$reset()
  $reviewStore.$reset()
})

const handleResize = () => {
  isMobile.value = window.innerWidth <= 800

  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}
</script>

<style></style>
