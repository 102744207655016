import {useCommonStore} from '@/stores/common'

export function useValidationComposable() {
  const {setCertifyPopup, setConfirmPopup} = useCommonStore()

  /**
   * 핸드폰 번호 validation
   * @param target
   * @returns {Boolean}
   */
  const isPhoneNumberValidation = target => {
    const patternPhone = /01[016789]-[^0][0-9]{2,3}-[0-9]{3,4}/
    return patternPhone.test(target)
  }

  const isPhoneNumberValidationWithoutHyphen = target => {
    const patternPhone = /01[016789][^0][0-9]{2,3}[0-9]{3,4}/
    return patternPhone.test(target)
  }
  //핸드폰 번호 마스킹
  const handPhoneMasking = {
    mask: '###-####-####',
  }
  /**
   * 인증 없을때 confirmPopup 공통 메서드
   * @returns {Boolean}
   */
  const isCertifyPopup = () => {
    const confirmParams = {
      subMessage: '정보 확인 및 수정을 위해서<br/>' + '본인 인증이 필요합니다.<br/>' + '본인 인증을 수행하시겠습니까?',
      callback: () => {
        setCertifyPopup()
      },
    }
    setConfirmPopup(confirmParams)
  }
  return {
    isCertifyPopup,
    handPhoneMasking,
    isPhoneNumberValidation,
    isPhoneNumberValidationWithoutHyphen,
  }
}
