export function useFormat() {
    /**
     * 천단위 기호 format
     * @param target
     * @returns {String}
     */
    const setThousandSeparator = target => {
        return `${target ? target.toLocaleString('ko-KR') : '0'}원`
    }
    return {
        setThousandSeparator,
    }
}

/**
 * arg의 모든 '-' 제거
 * @param target
 * @returns {String}
 */
export const formatRemoveHyphens = target => {
    return target.replaceAll('-', '')
}

export const formattedPhoneNumber = originPhoneNumber => {
    return originPhoneNumber.replace(/-/gi, '')
}