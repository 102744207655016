export const SECOND = 1000
export const MINUTE = 60 * SECOND
export const HOUR = 60 * MINUTE
export const DAY = 24 * HOUR

export const ROUTE_TYPE = {
  GIFT: 'giftcard',
  CAFE: 'cafe',
  BLOG: 'blog',
  INSTA: 'insta',
  KAKAO_PLUS: 'kakaoPlus',
}

export const REDIRECT_FLAG_KEY = 'redirect-home'
