<template>
  <div
    class="modal fade"
    :class="alertPopup.show ? 'show' : ''"
    id="exampleModalLive"
    tabindex="-1"
    aria-labelledby="exampleModalLiveLabel"
    aria-modal="true"
    role="dialog"
  >
    <div :class="alertPopup.show ? 'modal-show' : 'modal'" tabindex="-1">
      <div class="modal-custom">
        <div class="modal-content">
          <div class="modal-message-box" :class="alertPopup.subMessage ? 'sub' : 'main'">
            <span class="title" v-html="alertPopup.mainMessage.replace(/(?:\r\n|\r|\n)/g, '<br />')"></span>
            <span
              class="sub-msg mgt-12"
              v-if="alertPopup?.subMessage"
              v-html="alertPopup?.subMessage.replace(/(?:\r\n|\r|\n)/g, '<br />')"
            ></span>
          </div>
          <div class="footer">
            <a class="f-w-700" @click="onClickConfirm" type="button">확인</a>
            <a v-if="alertPopup?.errorCode === 'E001'" class="f-w-700 kakao" @click="moveKakao" type="button">
              <img :src="iconKakao" alt="카카오톡 상담하기 버튼 이미지" />
              카카오톡으로 문의하기
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useCommonStore} from '@/stores/common'
import {storeToRefs} from 'pinia'
import {REDIRECT_FLAG_KEY} from '@/core/common'
import iconKakao from '@img/icon/icon-kakao.png'

const {alertPopup} = storeToRefs(useCommonStore())

const onClickConfirm = () => {
  const redirectFlag = sessionStorage.getItem(REDIRECT_FLAG_KEY)
  if (redirectFlag === 'true') {
    location.replace('/m/AJD')
    sessionStorage.removeItem(REDIRECT_FLAG_KEY)
  }

  alertPopup.value.show = false
}

const moveKakao = () => {
  window.open('https://pf.kakao.com/_RGxcFxb/chat')
}
</script>

<style scoped></style>
