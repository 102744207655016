<template>
  <div class="logo" @click="onClickLogo">
    <img :src="activeImg" alt="로고" @error="replaceByDefault"/>
  </div>
</template>

<script setup>
import {useCommonStore} from '@/stores/common'
import {storeToRefs} from 'pinia'
import {computed} from 'vue'
import {isEmpty} from 'lodash-es'
import {useRoute, useRouter} from 'vue-router'
import {useCustomerStore} from "@/stores/customer";

const {commonCodeList, companyInfo, isMobile} = storeToRefs(useCommonStore())
const {setConfirmPopup} = useCommonStore()

const {customerInfo} = storeToRefs(useCustomerStore())

const $router = useRouter()
const $route = useRoute()

const activeImg = computed(() => {
    if (!isEmpty(companyInfo.value)) {
      return `${import.meta.env.VITE_APP_CDN_URL}/logo/logo_${companyInfo.value.initial}.svg`
    } else {
      return `${import.meta.env.VITE_APP_CDN_URL}}/logo/logo_no_image.svg`
    }
})
const replaceByDefault = e => {
  e.target.src = `${import.meta.env.VITE_APP_CDN_URL}/logo/logo_no_image.svg`
}

const onClickLogo = () => {
  if (!isMobile.value) {
    const confirmParams = {
      subMessage: '처음 화면으로 돌아가겠습니까?',
      callback: () => {
        const name = !isEmpty(companyInfo.value.initial) && !isEmpty(customerInfo.value.phoneNumber) ? 'Contract' : 'Auth'
        const {type} = $route.params

        if(type) {
          $router.push({name, params: {company: companyInfo.value.initial, type: $route.params.type}})
        }
      }
    }

    setConfirmPopup(confirmParams)
  }
}
</script>

<style scoped>
.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

header .logo {
  left: 20%;
  justify-content: center;
}

.logo img {
  /*display: flex;*/
  height: 28px;
}

.logo span {
  margin-left: 12px;
  color: #2d2e2e;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
}

@media (max-width: 800px) {
  .no-counseling .logo {
    margin-top: 33px;
  }

  .no-counseling .logo img {
    height: 38px;
  }

  .no-counseling .logo span {
    font-size: 22px;
    line-height: 22px;
  }

  .complete .logo {
    margin-top: 100px;
    align-items: flex-start;
  }

  .complete .logo img {
    height: 48px;
  }

  .complete .logo span {
    font-size: 28px;
    line-height: 35px;
  }

  .partner.logo {
    left: 0;
  }

  .banksalad .logo img {
    width: 85%;
  }
}
</style>
