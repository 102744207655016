<template>
  <div
    class="modal fade"
    :class="confirmPopup.show ? 'show' : ''"
    id="exampleModalLive"
    tabindex="-1"
    aria-labelledby="exampleModalLiveLabel"
    aria-modal="true"
    role="dialog"
    style="display: block"
  >
    <div :class="confirmPopup.show ? 'modal-show' : 'modal'" tabindex="-1">
      <div class="modal-custom">
        <div class="modal-content" :class="confirmPopup.type === 'delete' ? 'delete' : ''">
          <div class="modal-message-box" :class="confirmPopup.subMessage ? 'sub' : 'main'">
            <span class="title" v-html="confirmPopup.mainMessage.replace(/(?:\r\n|\r|\n)/g, '<br />')"></span>
            <span class="sub-msg mgt-12" v-html="confirmPopup.subMessage.replace(/(?:\r\n|\r|\n)/g, '<br />')"></span>
            <template v-if="confirmPopup.subMessage === '상품권을 저장하시겠습니까?'">
              <div class="warn-gift">
                <div class="warn-title">
                  <img :src="iconInfo" alt="정보 아이콘" />
                  꼭 확인해 주세요
                </div>
                <div class="warn-content">
                  안내해 드린 상품권 외에<br />
                  다른 경로로 받은 상품권 입력 시,<br />
                  <b>현금 교환 불가</b>합니다.
                </div>
              </div>
            </template>
          </div>
          <div class="footer double confirm-double">
            <a @click="onCancel" type="button" class="close">{{ confirmPopup.cancelBtnName || '취소' }}</a>
            <a @click="onClickConfirm" type="button" class="confirm">{{ confirmPopup.confirmBtnName || '확인' }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useCommonStore} from '@/stores/common'
import {storeToRefs} from 'pinia'
import iconInfo from '@/assets/img/common/icon-info.png'

const {confirmPopup} = storeToRefs(useCommonStore())

const onCancel = () => {
  confirmPopup.value.show = false
}
const onClickConfirm = () => {
  confirmPopup.value.onClickConfirm()
  confirmPopup.value.show = false
}
</script>

<style scoped>
.modal {
  background-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.warn-gift {
  margin-top: 20px;
  width: 250px;
}

.warn-title {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff8502;
  border-radius: 4px 4px 0 0;
  font-size: 14px;
  color: #fff;
  gap: 2px;
}

.warn-content {
  padding: 8px 16px;
  background-color: #f4f4f4;
  border-radius: 0 0 4px 4px;
  font-size: 12px;
  line-height: 150%;

  b {
    color: #c70c34;
    font-weight: 700;
  }
}
</style>
